function getEnvConfig() {
  let envConfig;
  switch (window.location.hostname) {
    default:
    case 'localhost':
      envConfig = {
        OAUTH_SERVICE_URL:
          'https://authenticationed1.lmiinc.test.expertcity.com/oauth',
        OAUTH_CLIENT_ID: '79697403-2e5f-4f2e-9d97-a92b576be6ce',
        OAUTH_REDIRECT_URI: 'http://localhost:3000/',
        EXTERNAL_ADMIN_URL:
          'https://admined1.lmiinc.test.expertcity.com/ext-admin/rest',
      };
      break;
    case 'ttyd-poc.ed.gtc.goto.com':
    case 'ttyd-poc.dev.gtc.goto.com':
      envConfig = {
        OAUTH_SERVICE_URL:
          'https://authenticationed1.lmiinc.test.expertcity.com/oauth',
        OAUTH_CLIENT_ID: '79697403-2e5f-4f2e-9d97-a92b576be6ce',
        OAUTH_REDIRECT_URI: 'https://ttyd-poc.dev.gtc.goto.com',
        EXTERNAL_ADMIN_URL:
          'https://admined1.lmiinc.test.expertcity.com/ext-admin/rest',
      };
      break;
    case 'ttyd-poc.stage.gtc.goto.com':
    case 'ttyd-poc.staging.gtc.goto.com':
      envConfig = {
        OAUTH_SERVICE_URL: 'https://authenticationstage.logmeininc.com/oauth',
        OAUTH_CLIENT_ID: 'a71c6bd3-0082-4e7d-8ecb-9e4616a13789',
        OAUTH_REDIRECT_URI: 'https://ttyd-poc.staging.gtc.goto.com',
        EXTERNAL_ADMIN_URL: 'https://adminstage.logmeininc.com/ext-admin/rest',
      };
      break;
    case 'ttyd-poc.live.gtc.goto.com':
    case 'ttyd-poc.prod.gtc.goto.com':
      envConfig = {
        OAUTH_SERVICE_URL: 'https://authentication.logmeininc.com/oauth',
        OAUTH_CLIENT_ID: '87a180a5-f111-42fb-99e4-a2fd0a050d1a',
        OAUTH_REDIRECT_URI: 'https://ttyd-poc.prod.gtc.goto.com',
        EXTERNAL_ADMIN_URL: 'https://admin.logmeininc.com/ext-admin/rest',
      };
      break;
  }
  return envConfig;
}

const EnvConfig = getEnvConfig();
export default EnvConfig;
