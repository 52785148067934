import React from 'react';

import { ExclamationTriangleFill, PlusSquare, DashSquare } from 'react-bootstrap-icons';
import AuthenticationError from './ExternalAdmin';

import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

export default class ErrorWidget extends React.Component {
  state = {
    showDetails: false,
  };

  toggleShowDetails = () => {
    const { showDetails } = this.state;
    this.setState({ showDetails: !showDetails });
  };

  render() {
    const { error } = this.props;
    const { showDetails } = this.state;

    const details = error.message;
    const contact = (
      <span>
        For assistance, contact GoToRoom dev team on slack{' '}
        <a
          href="https://logmein.slack.com/archives/CD1MDGQJ0"
          target="_blank"
          rel="noopener noreferrer"
        >
          #gotoroom-dev
        </a>
        .
      </span>
    );

    let title;
    let message;
    switch (error.constructor) {
      case AuthenticationError:
        title = 'Oops! Something went wrong.';
        message = (
          <p>
            Unable to authenticate. Try reloading the page to fix the issue.{' '}
            {contact}
          </p>
        );
        break;
      default:
        title = 'Oops! Something went wrong.';
        message = (
          <p>
            An unexpected error occurred. Try reloading the page to fix the
            issue. {contact}
          </p>
        );
    }

    return (
      <div>
        <div className="row mb-3">
          <ExclamationTriangleFill
            className="col text-center"
            style={{ width: '5rem', height: '5rem', color: '#f5d400' }}
          />
        </div>
        <div className="row justify-content-md-center">
          <div className="col col-lg-8">
            <h4 className="text-center">{title}</h4>
            {message}
            <button
              className="btn"
              type="button"
              onClick={this.toggleShowDetails}
            >
              {showDetails ? (
                <>
                  <DashSquare /> Hide details
                </>
              ) : (
                <>
                  <PlusSquare /> Show details
                </>
              )}
            </button>
            <p className="mt-3">{showDetails && details}</p>
          </div>
        </div>
      </div>
    );
  }
}
