import EnvConfig from './EnvConfig';

export class AuthenticationError extends Error {
  constructor(message = '') {
    super(message);
    this.name = 'AuthenticationError';
  }
}

export function GetMe(accessToken) {
  const url = `${EnvConfig.EXTERNAL_ADMIN_URL}/me`

  let headers = [];
  if (accessToken) {
    headers.push(['Authorization', `Bearer ${accessToken}`]);
  }

  return fetch(url, {
    method: 'GET',
    headers,
  })
    .then((response) => {
      if (response.status === 401) {
        return Promise.reject(new AuthenticationError('Unauthorized'));
      }
      return response.json();
    })
}

export default GetMe;