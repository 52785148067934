import React, { createContext, useContext } from 'react';

import EnvConfig from './EnvConfig';
import {GetMe, AuthenticationError} from './ExternalAdmin';
import ErrorWidget from './ErrorWidget';
import { Spinner } from './Spinner';


const authContext = createContext();
export function useAuthContext() {
  return useContext(authContext);
}

function processOauthCallback() {
  const regex = window.location.hash.match(
    /#access_token=([^&]*).*state=([^&]*)/,
  ) || [''];
  if (regex.length === 3) {
    sessionStorage.setItem('accessToken', regex[1]);
    window.location.hash = decodeURIComponent(regex[2]);
  }
}

export default class AuthProvider extends React.Component {
  state = {
    loading: true,
    loggedIn: false,
    userEmail: undefined,
  };

  componentDidMount() {
    processOauthCallback();

    GetMe(sessionStorage.getItem('accessToken'))
      .then((me) => {
        this.setState({ loading: false, loggedIn: true, userEmail: me.email });
      })
      .catch((error) => {
        if (error.constructor === AuthenticationError) {
          window.location.href =
            `${EnvConfig.OAUTH_SERVICE_URL}/authorize?` +
            `response_type=token` +
            `&client_id=${
              EnvConfig.OAUTH_CLIENT_ID
            }&redirect_uri=${encodeURIComponent(
              EnvConfig.OAUTH_REDIRECT_URI,
            )}&state=${encodeURIComponent(window.location.hash || '#')}`;
        } else {
          this.setState({ loading: false, error });
        }
      });
  }

  handleLogout = () => {
    sessionStorage.removeItem('accessToken');
    window.location.href =
      `${EnvConfig.OAUTH_SERVICE_URL}/logout?` +
      `client_id=${EnvConfig.OAUTH_CLIENT_ID}&redirect_uri=${encodeURIComponent(
        EnvConfig.OAUTH_REDIRECT_URI,
      )}`;
  };

  render() {
    const { loading, loggedIn, userEmail, error } = this.state;
    const { children } = this.props;

    const auth = {
      loggedIn,
      userEmail,
      logout: this.handleLogout,
    };

    return (
      <authContext.Provider value={auth}>
        {loading && (
          <div
            style={{
              height: '100%',
              width: '100%',
              position: 'fixed',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Spinner />
          </div>
        )}
        {error && <ErrorWidget error={error} />}
        {!loading && !error && children}
      </authContext.Provider>
    );
  }
}
