import './App.css';
import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Robot, QuestionCircle } from 'react-bootstrap-icons';

export const ChatRow = (props) => {
  const { role, text } = props;


  return (
    <>
      <Row className='justify-content-md-center mb-3'>
        {role === 'user' ?
          <Col xs="auto">
            <QuestionCircle size={32} color='var(--bs-primary)'/>
          </Col>
          :
          <Col  style={{ maxWidth:'100px'}}/>
        }
        <Col className='chat-bubble px-4 py-2' style={{ maxWidth:'600px'}}>
          {text}
        </Col>
        {role === 'agent' ?
          <Col xs="auto">
            <Robot size={32} color='var(--bs-warning)'/>
          </Col>
          :
          <Col style={{ maxWidth:'100px'}}/>
        }
      </Row>
    </>

  );
}
