import './App.css';
import * as React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import { useAuthContext } from './AuthProvider';
import { Chat } from './Chat';

const App = () => {

  const auth = useAuthContext();

  return (
    <>
      <Navbar className='bottom-border mb-3'>
        <Navbar.Brand>
          <img
            src="goto.svg"
            width="141"
            height="32"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
          Talk To Your Data (PRE-ALPHA)
        </Navbar.Brand>

        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text className='mx-4'>
            Signed in as: <a href='#home'>{auth.userEmail}</a>
          </Navbar.Text>
        </ Navbar.Collapse>

      </Navbar>

      <Chat />
    </>
  );

}

export default App;