import './App.css';
import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { ChatRow } from './ChatRow';

export class Chat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [{ role: 'user', text: 'This is a question' }, { role: 'agent', text: 'Hi, here is my answer' }],
    };
  }


  handleSendMessage = () => {
    let { messages, question } = this.state;
    messages.push({ role: 'user', text: question });
    this.setState({ messages: messages, question: '' });
  }

  render() {
    const { question } = this.state;

    return (
      <>
        <Container>
          {this.state.messages.map((msg, i) => <ChatRow role={msg.role} text={msg.text} key={i} />)}
        </Container>

        <Navbar className='top-border' fixed="bottom">
          <InputGroup className="my-2 mx-auto px-4" style={{ maxWidth: '700px' }}>
            <Form.Control
              placeholder="How is my call center doing?"
              value={question || ''}
              onChange={(event) =>
                this.setState({ question: event.target.value })
              }
              onKeyDown={(event) => {
                if (event.code === "Enter" && question) this.handleSendMessage()
              }}
            />
            <Button
              variant="primary"
              disabled={!question}
              onClick={this.handleSendMessage}
            >
              Send
            </Button>
          </InputGroup>
        </Navbar>
      </>
    );
  }
}

export default Chat;